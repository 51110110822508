<template>
    <div>
        <d-loading :isLoading="isLoading"></d-loading>
        <v-row>
            <v-col v-for="(item, index) in tableData" :key="index" cols="12" xs="12" sm="6" md="4" lg="4">
                <SingleMySheetCard :sheet="item"></SingleMySheetCard>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import SingleMySheetCard from './components/SingleMySheetCard'
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {getMySalarySheets} from '@/api/salary'
export default {
    created(){
         this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Lương", route: "/salary/my-salary-sheets" },
          { title: "Bảng lương của tôi", route: "/salary/my-salary-sheets" }
        ]);
        this.fetchData();
    },
    methods:{
        fetchData(){
            this.isLoading = true;
            getMySalarySheets().then(res=>{
                this.tableData = res.data;
                this.isLoading = false;
            })
        }
    },
    data() {
        return {
            isLoading:false,
            tableData:[]
        }
    },
    components:{
        SingleMySheetCard
    }
}
</script>