<template>
    <v-card>
        <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline">
            <span>{{sheet.sth_name}}</span>
          </v-list-item-title>
          <v-list-item-subtitle>
              {{sheet.sth_from|toDMY}} - {{sheet.sth_to|toDMY}}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-chip
          label
          :color="sheet.sth_status|salarySheetStatusColor"
          outlined
        >{{ sheet.sth_status|salarySheetStatusText }}</v-chip>
      </v-list-item>
      <v-divider></v-divider>
      <div style="padding:0 10px 10px 10px">
        
          <v-row>
              <v-col cols="6">
                  Số ca: {{sheet.shifts_num}}
              </v-col>
              <v-col cols="6">
                  Số giờ: {{sheet.sum_w_hours_diff}} - <span style="color:#009688">
            <b>{{sheet.sum_w_hours}}</b>
          </span>
              </v-col>
          </v-row>
      
          <v-row>
              <v-col cols="6">
                  Ngày công: <span>{{(sheet.workdays_num)|numberFR}}/{{(sheet.work_day_const)|numberFR}}</span>
              </v-col>
              <v-col cols="6">
                  Loại lương: <span>{{sheet.s_value|numberF0}}/{{sheet.s_type|salaryTypeText}}</span>
              </v-col>
          </v-row>
          <v-row>
              <v-col cols="6">
                  Lương: <span>
            {{sheet.w_salary_cal|numberF0}}
            <span
              style="color:#009688"
              v-if="sheet.w_salary_cal!=sheet.w_salary_edt"
            >-{{sheet.w_salary_edt|numberF0}}</span>
          </span>
              </v-col>
              <v-col cols="6">
                  Thưởng phạt: <span>{{sheet.other_value|numberF0}}</span>
              </v-col>
          </v-row>
          <v-row>
              <v-col cols="6">
                  Ghi chú: {{sheet.admin_note}}
              </v-col>
              <v-col cols="6">
                  <span style="font-weight: bold;">Tổng: {{sheet.total|numberF0}}</span>
              </v-col>
          </v-row>
      </div>
    </v-card>
</template>
<script>
export default {
    props:{
        sheet:Object
    }
}
</script>