import request from '@/utils/request'
export function getSalarySetupTable(data) {
    return request({
      url: 'salary/setup-table',
      method: 'post',
      data: data
    })
  }
  export function updateUserSalarySetup(data) {
    return request({
      url: 'salary/update-user-setup',
      method: 'post',
      data: data
    })
  }
  export function getSalarySheetHeaders(data) {
    return request({
      url: 'salary/salary-sheets',
      method: 'post',
      data: data
    })
  }
  export function createNewSalarySheet(data) {
    return request({
      url: 'salary/new-salary-sheet',
      method: 'post',
      data: data
    })
  }
  export function getSalarySheetDetail(data) {
    return request({
      url: 'salary/sheet-detail',
      method: 'post',
      data: data
    })
  }
  export function calSalarySheet(data) {
    return request({
      url: 'salary/cal-salary-sheet',
      method: 'post',
      data: data
    })
  }
  export function updateSheetLines(data) {
    return request({
      url: 'salary/update-sheet-lines',
      method: 'post',
      data: data
    })
  }

  export function updateSheetHeader(data) {
    return request({
      url: 'salary/update-sheet-header',
      method: 'post',
      data: data
    })
  }
  export function updateSheetHeaderStatus(data) {
    return request({
      url: 'salary/update-sheet-header-status',
      method: 'post',
      data: data
    })
  }
  export function getMySalarySheets(data) {
    return request({
      url: 'salary/my-salary-sheets',
      method: 'post',
      data: data
    })
  }
  

  
  
  
